import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/fr/Layout/index"

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  width: "100%",
}
const headingStyles = {
  display: "flex",
  position: "relative",
  justifyContent: "center",
  alignItems: "center"
}

const paragraphStyles = {
  marginBottom: 48,
  display: "flex",
  position: "relative",
  justifyContent: "center",
  alignItems: "center"
}

const LinkStyles = {
  display: "flex",
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
  color: "black"
}


// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <Layout>
        <title>Not found</title>
        <h1 style={headingStyles}>Page not found</h1>
        <h2 style={paragraphStyles}>
          There's nothing Here
        </h2>
        <Link style={LinkStyles} to="/fr">Go home</Link>.
      </Layout>
    </main>
  )
}

export default NotFoundPage
